import React from 'react'
import H2 from '../components/Blocks/H2/h2';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import QuoteSection from '../components/QuoteSection/quoteSection';
import SEO from '../components/SEO/seo';
import VideoFullscreen from '../components/VideoFullscreen/videoFullscreen';


const getBlockComponent = (block, meta) => {
    const blockType = block.type;

    if (blockType === "heading_1") {
        return <HeroSection animationType={meta.animation ? meta.animation : "globe"} heading={block.heading_1.text[0].text.content} desc="" />
    } else if (blockType === "paragraph") {
        const paragraphTextArray = block.paragraph.text;
        if (block.paragraph.text.length === 0) {
            return <Section>
                <Paragraph style={{ margin: 0 }}>{""}</Paragraph>
            </Section>
        }
        if (paragraphTextArray.length > 1) {
            return <Section>
                <Paragraph style={{ margin: 0 }}>{paragraphTextArray.map(t => t.text.content).join("")}</Paragraph>
            </Section>
        }
        return <Section>
            <Paragraph style={{ margin: 0 }}>{paragraphTextArray[0].text.content}</Paragraph>
        </Section>
    } else if (blockType === "heading_2") {
        return <Section nomargin><H2>{block.heading_2.text[0].text.content}</H2></Section>
    } else if (blockType === "video") {
        if (block.video.type === "external") {
            return <VideoFullscreen margins youtubeVideoID={block.video.external.url.match(/(?<=&v=)(.*?)(?=&)/g)} />
        }
    } else if (blockType === "image") {
        if (block.image.type === 'external') {
            if (!block.image.external.url.match(/^data/g)) {
                return <FullscreenPhotoSection alt={(block.image.caption && block.image.caption.length > 0) ? block.image.caption[0].plain_text : ""} notgatsbyimage fileURL={block.image.external.url} />
            }
        } else {
            return <FullscreenPhotoSection alt={(block.image.caption && block.image.caption.length > 0) ? block.image.caption[0].plain_text : ""} notgatsbyimage fileURL={block.image.file.url} />
        }
    }
}

const CaseTemplate = ({ pageContext }) => {
    const { data } = pageContext;
    const isThereMeta = data.find(block => block.type === 'code');
    const meta = isThereMeta ? JSON.parse(isThereMeta.code.text[0].text.content.replace(/(\n)|(\t)/g, "")) : null;

    return (
        <Layout>
            <SEO title={meta ? meta.title : "Project"} description={meta ? meta.description : "Design Project"} />
            {data.map(block => getBlockComponent(block, meta))}
            {(meta && meta.performance) && <PerformanceSection performances={meta.performance} salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" />}
            {(meta && meta.quote) && <QuoteSection quote={meta.quote.text} name={meta.quote.by} salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" />}
        </Layout>
    )
}

export default CaseTemplate
